$(document).foundation();





jQuery(function($){

$("#modifier_compte").click(function(event){
   event.preventDefault();
   $('.inputDisabled').prop("disabled", false); // Element(s) are now enabled.
   $('#modifier_compte').hide(); // Element(s) are now enabled.
   $('#bvalidation').removeClass( "hide" );
});

$("#info_confirmation").click(function() {
		if ($(this).is(":checked")) {
			$("#bvalidation").removeAttr("disabled");
		} else {
			$("#bvalidation").attr("disabled", "disabled");
		}
});

	$(function() {
	$('.search').bind('click', function() {
			$(".search-field").toggleClass("expand-search");
			// if the search field is expanded, focus on it
			if ($(".search-field").hasClass("expand-search")) {
				$(".search-field").focus();
			}
		});
	});

	function w_ouvrir(){
		if ($( "#erreur_form" ).html() !== undefined){
					$('#w_contact').foundation('open');}
		if ($( "#erreur_form_login" ).html() !== undefined){
					$('#w_connexion').foundation('open');}

	}

	$(document).ready(function(){
				w_ouvrir();
		});
});

